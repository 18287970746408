import React from "react";

//assets
import tealLogo from "assets/img/logo/teal-logo-only.svg";

function MapMarker() {

return (
    <div className="map-marker-container">
        <img
            alt="logo"
            src={tealLogo}
            style={{height: 20}}
        />
    </div>
);
}

export default MapMarker;