import React from "react";

import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

import { useLocation } from "react-router-dom";
import { isOnHomePage } from "methods/helperMethods";  

//assets
import whiteLogo from "assets/img/logo/white-full.svg";
import blackLogo from "assets/img/logo/black-full.svg";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  let location = useLocation();

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        (document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299)
      ) {
        setNavbarColor("");
      } else if (
        (document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300) &&
        (isOnHomePage(location.pathname)) // Check that you are on homepage
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    if (!isOnHomePage(location.pathname)) {
      setNavbarColor("");
    }

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, [location]);



  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container style={{display: "flex"}}>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="/"
            title="Amsoo Properties"
          >
            <img
                alt="logo"
                src={navbarColor ? whiteLogo : blackLogo}
                style={{height: 50, opacity: navbarColor ? 1 : 0.7, paddingTop: navbarColor ? "0px" : "15px"}}
            />
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/#Availability"
                title="Available Rooms"
              >
                <div>Rooms Available</div>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="/interestform"
                title="Interest Form"
              >
                <div>Interest Form</div>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;