import React from "react";

// reactstrap components
import { 
    Container
} from "reactstrap";


function SectionAvailability() {

return (
    <div className="footer-section">
        <Container>
            <div className="footer-container">
                <div className="left-footer">
                    <div className="footer-link">
                        <a href="/#Availability">Rooms Available</a>
                    </div>
                    <div className="footer-link">
                        <a href="/interestform">Interest Form</a>
                    </div>
                </div>
                <div className="right-footer">
                    Contact Us <br/>
                    info@amsooproperties.com
                </div>
            </div>
        </Container>
    </div>
);
}

export default SectionAvailability;