// Services
import { collection, doc, addDoc, getDoc, Timestamp } from "firebase/firestore"
import { db } from "services/firebase.config"
import { ref, getDownloadURL } from "firebase/storage"
import { storage } from "services/firebase.config";

// Constants
const DAYS_TO_EXPIRE = 7;

// ---------------------------------------------------------------
// GENERIC HELPER METHODS
// ---------------------------------------------------------------

export const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// Get month and year of available date. If the available month and year was in the past,
// then get the current month and year.
export const getMonthYear = (d) => {
    const currentDate = new Date();
    if (d < currentDate) {
        d = currentDate
    }
    const month = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(d)
    return `${month} ${d.getFullYear()}`
}

export const getUnitUrl = (house, unit) => {
    return `${house}-${unit}`.replace(' ','').toLowerCase()
}

export const isDevMode = () => {
    return process.env.NODE_ENV === 'development'
}

export const isOnHomePage = (pathname) => {
    const homePathnames = ["", "/"]
    return homePathnames.includes(pathname)
}

export const parseDescription = (description, onlyLocation) => {
    if (!description || typeof(description) === "string") return description

    let formattedDescription = [];
    description.forEach((section, index) => {
        const sectionName = Object.keys(section)[0];
        if (!onlyLocation) {
            if (sectionName === "LOCATION") return
            if (sectionName !== "unnamed") {
                formattedDescription.push(<div className="description-title" key={`${sectionName}${index}`}>{sectionName}</div>)
            }
            formattedDescription.push(<div className="description-words" key={`${sectionName}${index}-words`}>{section[sectionName]}<br></br></div>)
        } else {
            if (sectionName === "LOCATION") {
                formattedDescription.push(<div key={`${sectionName}${index}-words`}>{section[sectionName]}</div>)
            }
        }
    })
    return formattedDescription
}


// ---------------------------------------------------------------
// FIREBASE HELPER METHODS
// ---------------------------------------------------------------

export const getListingData = async ({params}) => {
    const listingId = params.listingId
    const docRef = doc(db, "units", listingId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return {listingData: docSnap.data()}
    } else {
        console.log("Could not retreive data from listing ID given")
    }
}

export const getPhotoUrl = async (house, imagePath, callback) => {
    await getDownloadURL(ref(storage, `${house}/${imagePath}`))
        .then((url) => {callback(url)})
        .catch((error) => {
            console.log(`Error retreiving image link from storage for ${house}/${imagePath}`)
            console.log(error)
        })
}

export const addToInterestList = async (first_name, last_name, email, phone_number) => {
    let expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + DAYS_TO_EXPIRE);
    await addDoc(collection(db, "interest_list"), {
        first_name: first_name,
        last_name: last_name,
        email: email,
        phone_number: phone_number,
        createdAt: Timestamp.now(),
        expiresOn: Timestamp.fromDate(expireDate),
    })
    .then(() => console.log("Added to interest list"))
}
