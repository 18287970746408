import React from "react";
import { Outlet } from "react-router-dom";

import IndexNavbar from "components/IndexNavbar";
import SectionFooter from "components/SectionFooter"

function Root() {
    return (
        <>
            <IndexNavbar />
            <div className="main">
                <Outlet />
                <SectionFooter />
            </div>
        </>
    )
}

export default Root;