import React from "react";

// reactstrap components
import { 
    Container
} from "reactstrap";


function SectionAbout() {

return (
    <div className="about-amsoo">
        <Container style={{maxWidth: "800px"}}>
                <h2>Rent with ease!</h2>
                <h4>
                    Amsoo properties provide quick and easy room rentals in the Austin area. This 
                    housing option gives you the comfort of living in a house at a fraction of the price 
                    of an apartment.
                </h4>
        </Container>
    </div>
);
}

export default SectionAbout;