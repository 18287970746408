import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import GoogleMap from 'google-maps-react-markers'

// reactstrap components
import { 
    Container,
    Button,
    Modal,
    Spinner
} from "reactstrap";

// helpers
import { 
    getMonthYear, 
    parseDescription,
    capitalizeFirstLetter,
    getPhotoUrl,
    isDevMode
} from "methods/helperMethods";
import { amenityMapping } from "methods/amenities";

// router
import { useLoaderData } from "react-router-dom";

// services
import { getDoc } from "firebase/firestore"
import MapMarker from "./MapMarker";

function SectionListing() {
    const { listingData } = useLoaderData();
    const [houseData, setHouseData] = useState({amenities: [], location: {}});
    const [imageItems, setImageItems] = useState([]);
    const [heroImage, setHeroImage] = useState('');
    const [imageModal, setImageModal] = useState(false);
    const [isMapOpen, setIsMapOpen] = useState(false);
    const [isModalImagesLoading, setModalImagesLoading] = useState(true);

    useEffect(() => {
        getHeroImage()
        getPhotos()
        getHouseData()
    }, [listingData])

    const getHeroImage = async () => {
        let heroImageUrl = '';
        await getPhotoUrl(listingData.house, listingData.heroImages[0], (url) => {heroImageUrl = url})
        setHeroImage(heroImageUrl)
    }

    const getPhotos = async () => {
        const items = [];
        if (!listingData.images) {
            return
        }
        for await (const imagePath of listingData.images) {
            await getPhotoUrl(
                listingData.house, 
                imagePath, 
                (url) => {
                    items.push({src: url, key: url})
                }
            )
        }
        setImageItems(items)
        console.log(items)
        setModalImagesLoading(false)
    }

    const getHouseData = async () => {
        if (listingData.linkedHouse) {
            const docSnap = await getDoc(listingData.linkedHouse);
            if (docSnap.exists()) {
                setHouseData(docSnap.data())
            } else {
                console.log("Could not retreive house data from listing data")
            }
        }
    }

    const images = imageItems.map(image => {
        return (
            <div className="unit-image-container" key={image.key}>
                <img className="unit-image" alt="unit" src={image.src}/>
            </div>
        )
    })

    const amenities = houseData.amenities.map(amenity => {
        const amenityLogo = amenityMapping[amenity]
        return (
            <div className="amenity-group" key={amenity}>
                <span className="material-symbols-rounded">{amenityLogo}</span>
                <div className="amenity-name"><b>{amenity}</b></div>
            </div>
        )
    })

    const splitAmenities = () => {
        // NOTE: Current setup does not change column count on window resize
        let columnSplit = 3;
        if (window.innerWidth < 1000) columnSplit = 2
        if (window.innerWidth < 500) columnSplit = 1

        const column1 = amenities.filter((_, index) => index % columnSplit === 0);
        const column2 = amenities.filter((_, index) => index % columnSplit === 1);
        const column3 = amenities.filter((_, index) => index % columnSplit === 2);
        
        return (
            <div className="amenity-columns">
                <div className="amenity-column-1">{column1}</div>
                {columnSplit > 1 ? <div className="amenity-column-2">{column2}</div> : null}
                {columnSplit > 2 ? <div className="amenity-column-3">{column3}</div> : null}
            </div>
        )
    }

return (
    <Container style={{"maxWidth": "800px"}}>
        <h2 className="section-heading">{`${listingData.house} — Unit ${listingData.unit}`}</h2>
        <div className="listing-image-container">
            <img className="listing-hero-image" alt="primary room" src={heroImage}/>
            <Button default className="hero-image-button" onClick={() => setImageModal(true)}>View All Photos</Button>
        </div>
        <Modal
            isOpen={imageModal}
            className="modal-lg"
            modalClassName="bd-example-modal-lg"
            toggle={() => setImageModal(false)}
        >
            <div className="image-modal-header">
                <h4>{`${listingData.house} — Unit ${listingData.unit}`} Photos</h4>
                <Button
                    aria-label="Close"
                    className="btn-link"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => setImageModal(false)}
                >
                    <span className="material-symbols-rounded">close</span>
                </Button>
            </div>
            <div className="modal-body">
                {isModalImagesLoading ? 
                    <div className="spinner-container">
                        <Spinner className="spinner"/>
                    </div> : 
                    images
                }
            </div>
        </Modal>
        {/* Overview Section */}
        <div>
            <div className="listing-overview">
                <h3>Overview</h3>
                <Link to={`/interestform`} reloadDocument><Button className="reserve-button" size="lg">Reserve this room</Button></Link>
            </div>
            <div className="overview-boxes">
                <div className="roomhouse-container">
                    <div className="roomhouse-title">
                        <h5>House</h5>
                    </div>
                    <div className="roomhouse-details">
                        <div className="overview-detail">
                            <div>Bedrooms</div>
                            <div className="overview-value">{houseData.bedrooms}</div>
                        </div>
                        <div className="overview-detail">
                            <div>Bathrooms</div>
                            <div className="overview-value">{houseData.bathrooms}</div>
                        </div>
                        <div className="overview-detail">
                            <div>Location</div>
                            <div className="overview-value">{listingData.region}</div>
                        </div>
                    </div>
                </div>
                <div className="roomhouse-container">
                    <div className="roomhouse-title">
                        <h5>Bedroom</h5>
                    </div>
                    <div className="roomhouse-details">
                        <div className="overview-detail">
                            <div>Floor</div>
                            <div className="overview-value">{listingData.roomFloor}</div>
                        </div>
                        <div className="overview-detail">
                            <div>Bathroom</div>
                            <div className="overview-value">{capitalizeFirstLetter(listingData.bathroom)}</div>
                        </div>
                        {listingData.furnished ? 
                        <div className="overview-detail">
                            <div>Furnished</div>
                            <div className="overview-value">Yes</div>
                        </div> : null}
                        <div className="overview-detail">
                            <div>Available</div>
                            <div className="overview-value">{getMonthYear(listingData.availableDate.toDate())}</div>
                        </div>
                        <div className="overview-detail">
                            <div>Price</div>
                            <div className="overview-value">${listingData.price}/mo</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Description Section */}
        <div className="listing-description">
            {parseDescription(listingData.description, false)}
        </div>
        {/* Amenities Section */}
        <h3>Amenities</h3>
        <div className="listing-amenities">
            {splitAmenities()}
        </div>
        {/* Location Section */}
        <h3>Location</h3>
        <div className="location-description">{parseDescription(listingData.description, true)}</div>
        {isMapOpen ? null : <Button className="reserve-button" size="sm" onClick={() => setIsMapOpen(true)}>View On Map</Button>}
        {isMapOpen ? 
        <div className="map-container">
            <GoogleMap
                apiKey={isDevMode() ? null : process.env.REACT_APP_GOOGLE_MAPS_API_KEY }
                defaultCenter={{lat: houseData.location.mapCenter.lat, lng: houseData.location.mapCenter.lng}}
                defaultZoom={houseData.location.zoom}
                options={{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: true,
                    fullscreenControl: true
                }}
            >
                <MapMarker
                    lat={houseData.location.marker.lat}
                    lng={houseData.location.marker.lng}
                />
            </GoogleMap>
        </div> : null}
    </Container>
);
}

export default SectionListing;