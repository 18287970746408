import React from "react";

// reactstrap components
import { 
    Badge,
    Container
} from "reactstrap";

function SectionHowToApply({sectionTitle, stepNumber}) {

return (
    <Container style={{"maxWidth": "800px"}}>
        <div className="how-to-apply-container">
            <h3>{sectionTitle}</h3>
            <div className="step-container">
                <div className="step-number-container">
                    <p className="step-number">1</p>
                </div>
                <div className="step">
                    {stepNumber > 1 ? <Badge color="primary" pill>Completed</Badge> : null}
                    <div className="description-title">Fill out our interest form</div>
                    <div className="step-description">This quick interest form will help us understand how long of a lease you are looking for and what your timeline is for moving in.</div>
                </div>
            </div>
            <div className="step-container">
                <div className="step-number-container">
                    <p className="step-number">2</p>
                </div>
                <div className="step">
                    <div className="description-title">Call with our team</div>
                    <div className="step-description">A member from our team will reach out to schedule a call with you. This will give you the chance to ask questions about the place and learn more about the roommates.</div>
                </div>
            </div>
            <div className="step-container">
                <div className="step-number-container">
                    <p className="step-number">3</p>
                </div>
                <div className="step">
                    <div className="description-title">Schedule a tour</div>
                    <div className="step-description">We will work with you to schedule a tour. If you are moving from another city we can accommodate a virtual tour for you.</div>
                </div>
            </div>
            <div className="step-container">
                <div className="step-number-container">
                    <p className="step-number">4</p>
                </div>
                <div className="step">
                    <div className="description-title">Fill out an application</div>
                    <div className="step-description">Complete our application and background check online. We will let you know what documents we need you to upload.</div>
                </div>
            </div>
            <div className="step-container">
                <div className="step-number-container">
                    <p className="step-number">5</p>
                </div>
                <div className="step">
                    <div className="description-title">Sign the lease</div>
                    <div className="step-description">Once approved, you will sign your lease and send first month’s rent and security deposit to reserve the room.</div>
                </div>
            </div>
            <div className="step-container">
                <div className="step-number-container">
                    <p className="step-number">6</p>
                </div>
                <div className="step">
                    <div className="description-title">Move in!</div>
                    <div className="step-description">Get settled in your new room and meet your roommates!</div>
                </div>
            </div>
        </div>
        <div className="how-to-apply-container">
            <h3>Have questions?</h3>
            <div className="questions-description">
                Reach out to us at 
                <a href="mailto:info@amsooproperties.com" style={{color: "#099790", textDecoration: "none"}}><b> info@amsooproperties.com </b></a> 
                and we will respond within 24 hours. For a quicker response, fill out our interest form and we will reach out to you via phone.
            </div>
        </div>
    </Container>
);
}

export default SectionHowToApply;