import React from "react";

import SectionListing from "components/SectionListing";
import SectionHowToApply from "components/SectionHowToApply";

function Listing() {

    return (
        <div className="nonhome-page">
            <SectionListing />
            <SectionHowToApply sectionTitle="Interested in renting this room?" stepNumber={1}/>
            {/* Add other rooms available in this house*/}
        </div>
    )
}

export default Listing;