import React, { useEffect } from "react";
import { useState } from "react";

// reactstrap components
import { 
    Card, 
    CardImg, 
    CardBody, 
    CardTitle, 
    CardText, 
    Button,
    Container
} from "reactstrap";
import Skeleton from '@mui/material/Skeleton';

// assets
import imageNotFound from "assets/img/index_img/image_not_found.png"

// methods
import { getMonthYear } from "methods/helperMethods"

// services
import { collection, getDocs, query, where } from "firebase/firestore"
import { ref, getDownloadURL } from "firebase/storage"
import { db } from "services/firebase.config"
import { storage } from "services/firebase.config";
import { Link } from "react-router-dom";
import { getUnitUrl } from "methods/helperMethods";
const collectionRef = collection(db, 'units')


function SectionAvailability() {

const [isLoading, setIsLoading] = useState(true);
const [listings, setListings] = useState([]);

useEffect(() => {
    const getListings = async () => {
        const q = query(collectionRef, where("isAvailable", "==", true))
        await getDocs(q).then((units) => {
            let listingData = units.docs.map(doc => (
                {...doc.data(), 
                    id: doc.id, 
                    formattedDate: getMonthYear(doc.data().availableDate.toDate())
                }))
            setListings(listingData)
            setIsLoading(false)
            getPhotos(listingData)
        }).catch(err => {
            console.log(err)
        })
    }
    if (isLoading) {
        getListings()
    }
}, [isLoading])

const getPhotos = async (listingData) => {
    const updatedListings = [];
    for await (const listing of listingData) {
        if (listing.heroImages.length < 1) {
            console.log(`No image links to retreive for ${listing.house} unit ${listing.unit}`)
            updatedListings.push({...listing, heroLink: imageNotFound})
        } else {
            await getDownloadURL(ref(storage, `${listing.house}/${listing.heroImages[0]}`))
                .then((url) => {
                    updatedListings.push({...listing, heroLink: url})
                }).catch((error) => {
                    console.log(`Error retreiving image link from storage for ${listing.house} unit ${listing.unit}`)
                    console.log(error)
                    updatedListings.push({...listing, heroLink: imageNotFound})
                })
        }
    }
    setListings(updatedListings)
}

const roomCards = listings.map(listing => {
    let description = `${listing.furnished ? 'furnished ' : ''}${listing.roomType} room with ${listing.bathroom} bath`
    description = description.charAt(0).toUpperCase() + description.slice(1)
    return (
        <Card className="listing-card" style={{width: '20rem'}} key={listing.id}>
            {listing.heroLink ?
                <CardImg top 
                    src={listing.heroLink} 
                    alt={`${listing.house} unit ${listing.unit} photo`}
                    style={{
                        width: "100%",
                        maxHeight: "200px"
                    }}
                /> :
                <Skeleton variant="rectangle" height={200} style={{"borderTopLeftRadius": "12px", "borderTopRightRadius": "12px"}}/>
            }
            <CardBody>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <CardTitle><b>{`${listing.house} — Unit ${listing.unit}`}</b></CardTitle>
                    <div>{listing.region}</div>
                </div>
                <CardText>
                    {description}<br/>
                    Available {listing.formattedDate}
                </CardText>
                <div className="card-actions">
                    <Link reloadDocument to={`/listing/${getUnitUrl(listing.house, listing.unit)}`}>
                        <Button className="reserve-button">Learn More</Button>
                    </Link>
                    <div className="card-pricing">
                        Starting at
                        <div className="card-price">${listing.price}/mo</div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
})

return (
    <Container>
        <h2 className="section-heading" id="Availability">Availability</h2>
        <div className="availability-cards">
            {/* ADD CONDITIONAL IF LOADING, ADD CONDITIONAL IF NO LISTINGS */}
            {roomCards}
        </div>
    </Container>
);
}

export default SectionAvailability;