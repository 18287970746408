export const amenityMapping = {
    "Community Pool Access": "pool", // 4
    "Dining Area": "flatware", // 8
    "Dishwasher": "dishwasher_gen", // 9
    "Furnished Common Areas": "scene", // 1
    "High Speed Internet": "wifi", // 2
    "Outdoor Space": "outdoor_garden", // 5
    "Oven": "oven_gen", // 11
    "Private Parking": "garage", // 6
    "Refrigerator": "kitchen", // 10
    "Smart TV": "tv_gen", // 3
    "Washer & Dryer": "local_laundry_service" // 7
}