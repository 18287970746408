import React from "react";

// reactstrap components
import { Container } from "reactstrap";


function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(" + require("assets/img/index_img/chapala_living_room.jpeg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">Austin Room Rentals</h1>
            </div>
            <h2 className="presentation-subtitle text-center">
              <b>Quick and easy way to rent a room in Austin!</b>
            </h2>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;