import React from "react";

import IndexHeader from "components/IndexHeader";
import SectionAbout from "components/SectionAbout";
import SectionAvailability from "components/SectionAvailability";

function Home() {

    return (
        <>
            <IndexHeader />
            <SectionAbout />
            <SectionAvailability />
        </>
    )
}

export default Home;